import React from 'react';
import QRCode from 'react-qr-code';
import logo from "../Assets/images/greyLogo.png";
import { Box } from '@mui/material';
import { url } from '../configs';





interface QrGeneratorProps {
    value: string;
    peerId: string;
    showSpotcardLogo: boolean;
    qrBackgroundColor?: string;
}

const QrGenerator: React.FC<QrGeneratorProps> = ({ value, peerId, showSpotcardLogo, qrBackgroundColor }) => {
    const qrUrl = `${url}/account?id=${value}`;

    return (
        <Box sx={{ position: "relative", height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>

            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%", position: "relative", zIndex: 1 }}
                value={qrUrl}
                viewBox={`0 0 256 256`}
                bgColor={qrBackgroundColor || 'silver'}
                fgColor="#000000"
            />
            {showSpotcardLogo && (
                <img
                    src={logo}
                    alt="Spotcard Logo"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: 27,
                        width: 27,
                        zIndex: 2,
                    }}
                />
            )}
        </Box>
    );
}

export default QrGenerator;
