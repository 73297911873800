import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme/index";
import Routes from './Routes';
// import GetPeer from './pages/GetPeer';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
