import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    Box,
    Menu,
    MenuItem,
    IconButton,
    useMediaQuery,
} from "@mui/material";

import logo from '../Assets/images/logo.png';
import MenuIcon from '@mui/icons-material/Menu';

interface StateProps {
    isMenuOpen: boolean;
    active: number | null;
}

const Navbar = () => {
    const nav = [
        { link: "/#home", label: "Home" },
        { link: "/#features", label: 'Features' },
        { link: "/#pricing", label: 'Pricing' },
        { link: "/#contact", label: 'Contact' }
    ];

    const [state, setState] = useState<StateProps>({
        isMenuOpen: false,
        active: null
    });

    const { isMenuOpen, active } = state;
    const isMobile = useMediaQuery('(max-width:700px)');

    const handleMenuIcon = (e: any) => {
        setState({
            ...state,
            isMenuOpen: true,
        });
    };

    const handleNavClicked = (index: number) => {
        setState({
            ...state,
            active: index,
            isMenuOpen: false
        });
    };

    const handleClose = () => {
        setState({
            ...state,
            isMenuOpen: false
        });
    };

    return (
        <AppBar position="fixed">

            <CssBaseline />
            <Toolbar
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 6,
                    alignItems: 'center',
                    backgroundColor: 'silver',
                    padding: 4,
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }} >
                    <img src={logo}
                        style={{
                            height: 30,
                            width: 30,
                            objectFit: 'contain'
                        }}
                        alt='SpotCard' />
                    <Typography variant="h5" component="div"
                        sx={{ flexGrow: 1, fontWeight:"bold", color: 'primary.main' }}>
                        SpotCard
                    </Typography>
                </Box>

                {isMobile && (
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuIcon}
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                {(isMobile || isMenuOpen) && (
                    <Menu
                        open={isMenuOpen}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {nav.map((item, i) => (
                            <MenuItem
                                key={i}
                                selected={active === i}
                                onClick={() => handleNavClicked(i)}
                            >
                                <a href={item.link}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit'
                                    }}>
                                    {item.label}
                                </a>
                            </MenuItem>
                        ))}
                    </Menu>
                )}

                {!isMobile && (
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        {nav.map((item, i) => (
                            <a href={item.link}
                                key={i}
                                onClick={() => setState({ ...state, active: i })}
                                style={{
                                    fontSize: '1.6rem',
                                    padding: '8px 20px',
                                    borderRadius: '9999px',
                                    cursor: 'pointer',
                                    margin: '5px 10px',
                                    textDecoration: 'none',
                                    color: i === active ? '#7308C7' : '#333',
                                    borderBottom: i === active ? '3px solid #7308C7' : 'none',
                                }}
                            >
                                {item.label}
                            </a>
                        ))}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
