import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Policies from "../LandingSections/Policies";
import GetPeer from "../pages/GetPeer";
import Footer from "../LandingSections/Footer";
import Layout from "../Layout/Layout";
const Index = () => {
  return (
<Fragment>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/account" element={<GetPeer />} />
      </Routes>
      <Footer />
    </Fragment>
  );
};

export default Index;
