import { connect } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { configureStore } from '@reduxjs/toolkit';

const Connect = connect;

const initialState = {
    User: null,
    jwtToken: null,
    loggedIn: false,
    recent: [],
    contacts: [],
    mainContacts: [],
    myContacts: [],
    selectedPlatform: {
        _id: "661d3c2e31984b254875b7a7",
        name: "Phone",
        icon: "https://spotcard.nyc3.digitaloceanspaces.com/platforms/phone.png",
        type: "phone"
    },
    platforms: []
};

const reducer = (state = initialState, action: any) => {
    const { val, type } = action;
    if (type === 'multiplefields') {
        return { ...state, ...val };
    }
    return type === 'logOut' ? initialState : state;
};

const mapStateToProps = (state: any) => state;

const mapDispatchToProps = (dispatch: any) => {
    return {
        setRedux: (val: any): void => dispatch({ type: 'multiplefields', val }),
        logOut: () => dispatch({ type: 'logOut' })
    };
};

const persistConfig = {
    key: 'authType',
    storage,
};

const pReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: pReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
});

const persistor = persistStore(store);

export {
    Connect,
    reducer,
    mapStateToProps,
    mapDispatchToProps,
    persistor,
    store
};
