import { Box, Typography, Paper, Button } from '@mui/material';
import React from 'react';
import event from '../Assets/images/featureImages/event.jpeg';
import meet from '../Assets/images/featureImages/meet.jpeg';
import hide from '../Assets/images/featureImages/hide.jpeg';
import profile from '../Assets/images/featureImages/profile.jpeg';
import verified from '../Assets/images/featureImages/verified.jpeg';
import customize from '../Assets/images/featureImages/customize.jpeg'



const Features = () => {

    const moreFeatures = [
        {
            title: 'Get Verified Today!',
            image: verified,
            description: 'Give your profile the verified tick, distinguishing it from imposter profiles and adding a touch of credibility',
            buttonText: 'Start For Free'
        },
        {
            title: 'Manage Your Social Profiles',
            image: profile,
            description: 'Empower your social media presence: choose the profiles you want to showcase and effortlessly filter out the private ones for a polished online image',
            buttonText: 'Start For Free'
        },
        {
            title: 'Customize Your Card',
            image: customize,
            description: "Customize your card to reflect your brand: choose colors, background, add your logo, and opt to omit our platform's logo and url.",
            buttonText: 'Start For Free'
        },
        {
            title: "Hide Account",
            image: hide,
            description: 'Easily control your accessibility: hide your account to prevent unwanted contact during your downtime or when you prefer to remain undisturbed',
            buttonText: 'Start For Free'
        },
        {
            title: "Add Your Events",
            image: event,
            description: "Elevate your networking game by seamlessly integrating your events into your business card, ensuring your contacts stay informed and engaged",
            buttonText: "Start For Free"
        },
        {
            title: "Trace Meeting Event",
            image: meet,
            description: "Capture the essence of your client relationships by effortlessly documenting the events where you connected, adding depth and context to your interactions.",
            buttonText: "Start For Free"
        }
    ];

    return (
        <section id="features">
            <Box sx={{ marginY: 20 }}>
                <Typography data-aos="fade-down" sx={{ fontSize: { xs: 20, sm: 26, md: 36 }, marginBottom: 2, marginX: 3,fontWeight:500 }}>Exciting Features that will help you grow your Business</Typography>
                <Typography variant="body1" data-aos="fade-down" sx={{ marginBottom: 10, marginX: 3, fontSize: { xs: 16, sm: 18, md: 21 } }}>We’re known for Customizable Personal and Business Cards, but check out other essentials to take your business further online. </Typography>

                <Box sx={{ display: 'grid',gridTemplateColumns: 'repeat(auto-fill, minmax(440px, 1fr))',gap: 10,placeItems:'center',marginX:{xs:'auto',sm:5,md:20}}}>
                    {moreFeatures.map((feature, i) => (
                        <Paper key={i} data-aos="fade-up" sx={{display:'flex', flexDirection: { xs: 'column-reverse', sm: i % 2 === 0 ? 'row' : 'row-reverse' }, backgroundColor: 'silver', borderRadius: { xs: 15, md: 100 }, alignItems: 'center', justifyContent: 'center', width: { xs: 300, sm: '100%' } }} elevation={12}>
                            <Box sx={{ margin: 5, width: { md: 230 } }}>
                                <Typography data-aos="fade-up" variant='h5' sx={{ marginBottom: 4, textAlign: i % 2 === 0 ? 'center' : 'start', fontSize: { xs: 18, sm: 19, md: 22 } }}>{feature.title}</Typography>
                                <Typography data-aos="fade-up" variant='body1' sx={{ marginBottom: 4, textAlign: 'start', fontSize: { xs: 15, sm: 15, md: 16 } }}>{feature.description}</Typography>
                                <Button data-aos="fade-up" variant="outlined" sx={{ textAlign: i % 2 === 0 ? 'start' : 'center', fontSize: { xs: 13, sm: 13, md: 15 } }}>{feature.buttonText}</Button>
                            </Box>

                            <img src={feature.image} alt='' data-aos="fade-up" data-aos-delay={i * 200} style={{ width: 300, height: 500 }} />
                        </Paper>
                    ))}
                </Box>
            </Box >
        </section>
    )
}

export default Features;