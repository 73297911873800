import React from 'react'
import { Box, Typography } from '@mui/material';
import logo from '../Assets/images/logo.png';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {

    const socialLinks = [

        {
            path: "#",
            icon: < InstagramIcon sx={{ width: 16, height: 16 }} />,
        },
        {
            path: "#",
            icon: <XIcon sx={{ width: 16, height: 16 }} />,
        },
        {
            path: "#",
            icon: <WhatsAppIcon sx={{ width: 16, height: 16 }} />,
        },
        {
            path: "#",
            icon: <YouTubeIcon sx={{ width: 16, height: 16 }} />,
        }
    ];
    const currentYear = new Date().getFullYear();
    return (
        <>
            <Box sx={{
                marginBottom: 3,
                display: 'flex',
                flexDirection: { xs: 'column-reverse', md: 'row' },
                alignItems: { xs: 'flex-start', md: 'center' },
                marginLeft: { xs: 2, sm: 5 },
                gap: { md: 32, lg: 43 }

            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginLeft: { xs: 0, md: 5 }
                }}>
                    <Link to='/'><img src={logo} style={{ height: 25, width: 25, objectFit: 'contain', marginBottom: 2 }} alt='SpotCard' /></Link>
                    <Link to="/policies" style={{ fontSize: 12, color: 'primary.main' }}>Privacy Policies</Link>
                </Box>
                <Typography sx={{ fontSize: { xs: 11, md: 13 }, textAlign: 'center', marginTop: { xs: 3 }, marginBottom: { xs: 3 } }} > Copyright © SpotCard {currentYear}</Typography>

                <Box sx={{ display: 'flex', alignContent: 'flex-end', gap: 1, marginTop: { xs: 3, md: 0 } }}>
                    {
                        socialLinks.map((link, index) => (
                            <Link
                                to={link.path}
                                key={index}
                                style={{
                                    width: '1.7rem',
                                    height: '1.7rem',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: '#181A1E',
                                    borderRadius: '9999px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textDecoration: 'none',
                                    backgroundColor: 'transparent',
                                    color: 'inherit',
                                }}
                            //   onMouseEnter={(e) => { 
                            //     e.target.style.backgroundColor = 'primaryColor'; // Replace 'primaryColor' with your actual primary color
                            //     e.target.style.borderWidth = '0';
                            //   }}
                            //   onMouseLeave={(e) => { // Remove styles on mouse leave
                            //     e.target.style.backgroundColor = 'transparent';
                            //     e.target.style.borderWidth = '1px';
                            //   }}

                            >
                                {link.icon}
                            </Link>
                        )
                        )
                    }
                </Box>
            </Box >
        </>
    )
}

export default Footer;