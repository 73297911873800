import React from 'react';
import '@fontsource/roboto';
import { Card, Box, Typography } from "@mui/material";
import logo from "../Assets/images/logo.png";
import { Link } from 'react-router-dom';



const Policies = () => {
    const privacyPolicies = [
        {
            title: "Data Collection",
            description: "We collect user-provided data such as contact details and social media handles to generate QR codes and facilitate networking."
        },
        {
            title: "Information Usage",
            description: "The information provided by users is used solely for the purpose of generating QR codes and facilitating connections between users."
        },
        {
            title: "Data Security",
            description: "We employ industry-standard security measures to protect user data and ensure it is not accessed or disclosed without authorization."
        },
        {
            title: "Third-Party Access",
            description: "User data is not shared with third parties without explicit consent, except as required by law or to comply with legal obligations."
        },
        {
            title: "Cookies",
            description: "We may use cookies to enhance user experience and gather analytics data to improve our services. Users can manage cookie preferences."
        },
        {
            title: "Data Retention",
            description: "User data is retained only for as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law."
        },
        {
            title: "Opt-Out",
            description: "Users have the option to opt-out of certain data collection and processing activities, subject to limitations outlined in the privacy policy."
        },
        {
            title: "Children's Privacy",
            description: "Our services are not directed at children under the age of 13, and we do not knowingly collect personal information from them."
        },
        {
            title: "Updates to Privacy Policy",
            description: "We reserve the right to update this privacy policy periodically. Users will be notified of any changes via email or through the app."
        },
        {
            title: "User Rights",
            description: "Users have the right to access, rectify, and delete their personal information. Requests can be made through the app or by contacting us."
        },
        {
            title: "International Data Transfers",
            description: "User data may be transferred and processed in countries outside of the user's country of residence, subject to applicable laws and regulations."
        },
        {
            title: "Data Controller",
            description: "The data controller responsible for the processing of personal data is [Your Company Name], located at [Your Address]."
        },
        {
            title: "Data Protection Officer",
            description: "For inquiries regarding data protection or to exercise privacy rights, users can contact our Data Protection Officer at [DPO Email]."
        },
        {
            title: "User Consent",
            description: "By using our services, users consent to the collection, processing, and storage of their personal information as outlined in this policy."
        },
        {
            title: "Contact Information",
            description: "For questions or concerns regarding this privacy policy, users can contact us at [Contact Email]."
        },
        {
            title: "Terms of Service",
            description: "By using our services, users agree to abide by our Terms of Service, which govern the use of our platform."
        },
        {
            title: "Data Accuracy",
            description: "Users are responsible for ensuring the accuracy of the information provided. We are not liable for any inaccuracies or errors."
        },
        {
            title: "Data Deletion",
            description: "Upon request, user data will be deleted from our systems, unless retention is required by law or for legitimate business purposes."
        },
        {
            title: "Data Breach Notification",
            description: "In the event of a data breach that compromises user data, affected users will be notified promptly and provided with guidance."
        },
        {
            title: "User Feedback",
            description: "We welcome user feedback regarding privacy concerns and strive to address them promptly and transparently."
        }
    ];



    return (

        <>
            <Card
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 6,
                    alignItems: 'center',
                    backgroundColor: 'silver',
                    padding: { xs: 2, md: 3 },
                    justifyContent: 'space-between'

                }}

            >
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",

                        }}
                    >
                        <img
                            src={logo}
                            style={{ height: 30, width: 30, objectFit: "contain" }}
                            alt="SpotCard"
                        />
                        <Typography
                            sx={{
                                color: "#7308C7",
                                alignSelf: "flex-start",
                                marginLeft: 1,
                                fontSize: { xs: 20, sm: 25 },
                                fontWeight: 700,
                                fontFamily: "Roboto"
                            }}
                        >
                            SpotCard
                        </Typography>
                    </Box>
                </Link>

            </Card>

            <Box sx={{ marginTop: { xs: 13, md: 20 }, marginBottom: 10 }}>
                <Typography variant='h2' sx={{ fontFamily: "Roboto", fontSize: { xs: 20 }, fontWeight: 'bold', marginBottom: 4 }}>Privacy and Policies</Typography>
                {privacyPolicies.map((item, index) => {
                    return (
                        <Box key={index} sx={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft: { xs: 3, md: 30 }, marginRight: { xs: 3 }, marginBottom: { xs: 2 } }}>
                            <Typography variant='h4' sx={{ display: 'flex', alignContent: "flex-start", justifyContent: 'flex-start', fontSize: { xs: 14, sm: 15 }, fontFamily: "Roboto", fontWeight: 'bold' }}>{item.title}</Typography>
                            <Typography variant='body1' sx={{ display: 'flex', alignContent: "flex-start", textAlign: 'start', fontSize: { xs: 13.5, sm: 15 }, fontFamily: "Roboto" }}>{item.description}</Typography>
                        </Box>

                    );
                })}
            </Box>
        </>
    );
}

export default Policies;