import React, { useRef, useState } from 'react';
import { InputProps } from '../types';
import { Box, Typography, Grid, TextField, Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



interface StateProps {
    inputs: InputProps[];
    sending: boolean;
    expanded: boolean | string;
}
const ReachOut = () => {

    const formref = useRef(null)

    const initialInputs: InputProps[] = [
        { label: 'Name', value: '', field: 'name' },
        { label: 'Email Address', value: '', type: 'email', field: 'Email Address' },
        { label: 'Subject', value: '', field: 'Subject' },
        { label: 'Message', value: '', field: 'Write your message' }
    ];

    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        sending: false,
        expanded: false
    });

    const handleChange = (isExpanded: boolean, panel: string): void => {
        const expand = isExpanded ? panel : false;
        setState({
            ...state,
            expanded: expand
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
    };



    const { inputs, sending, expanded } = state

    const faqs = [
        {
            summary: "What is a Digital Business Card?",
            description: "Digital business cards are used by both individuals and businesses to quickly and sustainably exchange contact information. They’re more engaging, cost-effective, and eco-friendly than traditional physical business cards. Digital cards are also known as virtual or electronic."
        },
        {
            summary: "How do I share my Business Card?",
            description: "There are multiple ways to share a digital business card. The quickest way to share your digital business card in person is with your QR code. Somebody scans your QR code and he/she is able to get your profile with your contact information. With SpotCard you can also send your digital business card to someone via a text message, email, social media by simply clicking share."
        },
        {
            summary: "What is the benefit of a Digital Card?",
            description: "The main benefit of a digital business card is that it can be shared in both virtual and in-person settings. SpotCard even allows you to share your card with anyone."
        },
        {
            summary: "How do I sign up on SpotCard?",
            description: "You can sign up for SpotCard by downloading the SpotCard mobile app on the App Store or Google Play Store and then creating an account. You will then subscribe to one of our plans to get started."
        },
        {
            summary: "Can I share my account with others?",
            description: "SpotCard Personal is an individual plan for personal use. To create cards for your entire team, we offer SpotCard Business and SpotCard Verified. An individual can also choose to be verified."
        }
    ];


    return (
        <section id="contact">
            <Box
                sx={{
                    marginX: { xs: 2, sm: 4, md: 20 },
                    marginY: 20,
                    backgroundColor: '#fff',
                    paddingLeft: { xs: 2, sm: 4, lg: 25 },
                    paddingRight: { xs: 2, sm: 4, lg: 25 },
                    paddingTop: { xs: 3, md: 10 },
                    paddingBottom: 5,
                    borderTopLeftRadius: { xs: 60, md: 130 },
                    borderBottomRightRadius: { xs: 60, md: 130 }
                }}
            >
                <Typography data-aos="fade-down" sx={{ fontSize: { xs: 23, sm: 30, md: 34 }, textAlign: 'center', mb: 2, fontWeight: 600 }}>Get In Touch With Us</Typography>
                <Typography data-aos="fade-down" sx={{ marginBottom: 10 }}>Our dedicated team is ready to assist you every step of the way. Connect With Us Today!</Typography>

                <Box component="form" ref={formref} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, value, field, error }, i) => (
                                <Grid
                                    key={i}
                                    item
                                    xs={12}
                                    sm={label === 'Message' || label === 'Subject' ? 12 : 6}
                                    sx={{ width: '100%' }}
                                >
                                    <TextField
                                        data-aos="fade-up"
                                        required={label !== 'Subject'}
                                        fullWidth
                                        label={label}
                                        // autoFocus={i === 0}
                                        error={Boolean(error)}
                                        maxRows={label === 'Message' ? 10 : undefined}
                                        minRows={label === 'Message' ? 5 : undefined}
                                        multiline={label === 'Message'}
                                    />
                                </Grid>

                            ))

                        }
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 5 }}>
                        <Grid item xs={3}>
                            <LoadingButton
                                data-aos="fade-up"
                                disabled={sending}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                loading={sending}
                            >
                                SEND
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box sx={{ marginY: 15 }}>
                <Typography variant='h3' data-aos="fade-down" sx={{ marginBottom: 5, fontSize: { xs: 27, sm: 32, md: 35 } }}>FAQs</Typography>
                <Grid data-aos="fade-up" container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {faqs.map((faq, index) => (
                        <Grid item key={index} xs={12} sm={6} md={6} sx={{ marginX: { xs: 1, sm: 5 } }}>
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={(event, isExpanded) => handleChange(isExpanded, `panel${index}`)}
                                sx={{ width: '100%', borderRadius: 100 }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{ backgroundColor: 'silver', color: 'black' }}
                                >
                                    <Typography data-aos="fade-down">{faq.summary}</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body2' sx={{ textAlign: 'start' }} data-aos="fade-down">{faq.description}</Typography>
                                </AccordionDetails>


                            </Accordion>

                        </Grid>
                    ))}
                </Grid>
            </Box>

        </section>
    )
}

export default ReachOut;