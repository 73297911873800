import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7308C7',
      light: '#8d2fd6'
    },
    secondary: {
      main: '#19857b',
    },
    text: {
      primary: '#333',
      secondary: '#fff',
    },
    background: {
      default: '#F9F9F9',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
    },
  },
  transitions: {
    easing: {
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
    },
  },

});



export default theme