import axios from 'axios';
import { public_encryption_key, url } from '../configs';
import { store } from '../redux';
import { Crypt } from 'hybrid-crypto-ts';

const crypt = new Crypt();

export const Api = (data: any) => new Promise((resolve, reject) => {
    const jwtToken = store.getState().jwtToken,
        encrypted = crypt.encrypt(
            public_encryption_key,
            JSON.stringify({ ...data }),
        );
    axios.post(url, encrypted, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': "Bearer " + jwtToken
        }
    }).then(({ data }: any) => {

        resolve(data)
    }).catch((err: any) => {
        reject(err)
    })

})