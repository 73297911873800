import React, { Fragment } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Home from '../LandingSections/Home';
import Features from '../LandingSections/Features';
import Pricing from '../LandingSections/Pricing';
import ReachOut from '../LandingSections/ReachOut';
import Navbar from '../LandingSections/Navbar';

const Index = () => {

  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 100,
    // disable: "mobile",
    });
  }, []);




  return (
    <Fragment>
      <Navbar />
      <Home />
      <Features />
      <Pricing />
      <ReachOut />
    </Fragment>
  )
}

export default Index;