
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Card, CardActions, CardMedia, Typography, InputBase, Slide, Button, Dialog, IconButton, Toolbar, List, ListItemButton, ListItemText, Divider, ListItemAvatar, Tooltip, ListItem } from '@mui/material';
import logo from "../Assets/images/logo.png";
import { styled, alpha } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import AppStore from '../Assets/images/appstore.png';
import silver from '../Assets/images/silver.png';
import QrGenerator from '../QrCode';
import { Api } from '../helper';
import _ from 'lodash';
import queryString from 'query-string';
import { TransitionProps } from '@mui/material/transitions';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import Show from '../Components/common/show';


interface Profile {

  platformId: string;
  _id: string;
  value: string;
  verified: boolean
  type: string;
  url?: string;
  notes: string;

}
interface PlatformsProps {
  name: string;
  icon: string;
  numberOfAccounts: number;
  type: string,
  url: string
  profiles: Profile[];
  buttons?: string[];
  _id: string;
}
interface CustomCardProps {
  backgroundColor: string;
  backgroundImg: string;
  logo: string;
  qrBackgroundColor: string;
  textColor: string;
  showSpotcardLogoInQr: boolean;
  showSpotCardUrl: boolean;
  badgeColor: string;

}

interface PeerProps {
  platforms: PlatformsProps[];
  _id: string;
  Bio: string;
  Username: string;
  Image: string;
  verified: boolean;
  CustomCard: CustomCardProps

}

interface UserProfiles {
  peer: PeerProps;
  searchedValue: string;
  id: any
}



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#ECEBEB',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  marginLeft: 0,
  margin: 10,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GetPeer = () => {
  const [state, setState] = useState<UserProfiles>({
    searchedValue: '',
    peer: {
      platforms: [],
      _id: '',
      Username: '',
      Image: '',
      Bio: '',
      verified: false,
      CustomCard: {
        backgroundColor: "",
        backgroundImg: "",
        logo: "",
        qrBackgroundColor: "",
        textColor: "",
        showSpotcardLogoInQr: true,
        showSpotCardUrl: true,
        badgeColor: ""

      }
    },
    id: ""
  }
  )

  const [selectedProfile, setSelectedProfile] = useState<PlatformsProps | null>(null);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);


  const peerId = queryString.parse(window.location.search).id

  const getUserProfiles = () => {
    Api({
      controller: 'Users',
      action: 'getAccountInfo',
      id: peerId
    })
      .then((resp: any) => {
        if (resp.peer) {
          setState((prevState: any) => ({
            ...prevState,
            peer: {
              platforms: resp.peer.platforms,
              _id: resp.peer._id,
              Image: resp.peer.Image,
              Username: resp.peer.Username,
              Bio: resp.peer.Bio,
              verified: resp.peer.verified,
              CustomCard: resp.peer.customCard
            },
            searchedValue: resp.searchedValue || ''
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleSearchChange = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      searchedValue: event?.target.value.toLowerCase()
    }));
  }, 600);

  const filteredPlatforms = state.peer.platforms.filter(platform =>
    platform.name.toLowerCase().includes(state.searchedValue)
  );


  useEffect(() => {
    getUserProfiles();
    setState({
      ...state,
      id: peerId
    })
  }, []);

  const handleButtonClick = (action: any, profile: any, item?: any) => {
    switch (action) {
      case 'view':
        window.open(profile.url || selectedProfile?.url + (selectedProfile?.name === 'TikTok' ? '' : '/') + profile.value, '_blank');
        break;
      case 'copy':
        navigator.clipboard.writeText(profile.value);
        setCopyTooltipOpen(true);
        setTimeout(() => setCopyTooltipOpen(false), 2000);
        break;

      case 'call':
        window.open(`tel:${profile.value}`, '_self');
        break;
      case 'email':
        window.open(`mailto:${profile.value}`, '_self');
        break;
      case 'chat':
        window.open(`https://wa.me/${profile.value}`, '_blank');
        break;
      case 'save':
        const phoneNumber = profile.value;
        const contactURL = `tel:${phoneNumber}`;
        window.open(contactURL, '_blank');
        break;
      default:
        break;
    }
  };

  const renderButton = (button: any, profile: any) => {
    switch (button) {
      case 'view':
        return (
          <IconButton key={button} onClick={() => handleButtonClick(button, profile)}>
            < VisibilityOutlinedIcon />
          </IconButton>
        );
      case 'copy':
        return (
          <Tooltip key={button} title={copyTooltipOpen ? "Copied!" : "Copy to clipboard"} open={copyTooltipOpen}>
            <IconButton onClick={() => handleButtonClick(button, profile)}>
              <CopyIcon />
            </IconButton>
          </Tooltip>
        );
      case 'call':
        return (
          <IconButton key={button} onClick={() => handleButtonClick(button, profile)}>
            <CallOutlinedIcon />
          </IconButton>
        );
      case 'email':
        return (
          <IconButton key={button} onClick={() => handleButtonClick(button, profile)}>
            <MailOutlinedIcon />
          </IconButton>
        );
      case 'chat':
        return (
          <IconButton key={button} onClick={() => handleButtonClick(button, profile)}>
            <ChatIcon />
          </IconButton>
        );
      case 'save':
        return (
          <IconButton key={button} onClick={() => handleButtonClick(button, profile)}>
            <PersonAddOutlinedIcon />
          </IconButton>
        );
      default:
        return null;
    }
  };


  const [open, setOpen] = React.useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOpen = (profile: PlatformsProps) => {
    setSelectedProfile(profile);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(null);
  };
  return (
    <>
      <Card
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 6,
          alignItems: 'center',
          backgroundColor: 'silver',
          padding: { xs: 2, md: 3 },
          justifyContent: 'space-between',
          zIndex: 999
        }}
      >
        <Link to='/' style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              style={{ height: 30, width: 30, objectFit: "contain" }}
              alt="SpotCard"
            />
            <Typography
              sx={{
                color: "#7308C7",
                alignSelf: "flex-start",
                marginLeft: 1,
                fontSize: { xs: 20, sm: 25 },
                fontWeight: 700,
                fontFamily: "Roboto"
              }}
            >
              SpotCard
            </Typography>
          </Box>
        </Link>
      </Card>

      <Box sx={{ display: 'flex', marginTop: { xs: 12, sm: 20 }, justifyContent: 'center', marginBottom: 5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4' sx={{ display: 'flex', alignSelf: 'flex-start', fontWeight: '500', fontSize: { xs: 19, md: 24 } }}>Connect Profile</Typography>
          <Search sx={{ borderRadius: 3, marginTop: 2 }}>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: '#959595', fontSize: 20 }} />
            </SearchIconWrapper>
            <StyledInputBase placeholder='Search' inputProps={{ 'aria-label': 'search' }} onChange={handleSearchChange} />
          </Search>

          <Box sx={{ display: 'flex', gap: 4, marginTop: 4, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
            <Box>
              <Card sx={{
                display: 'flex', flexDirection: 'column', width: 350, padding: 2, marginBottom: 2, borderRadius: 2,
                backgroundColor: state.peer.CustomCard.backgroundColor,
                backgroundImage: `url(${state.peer.CustomCard.backgroundImg || silver})`
              }}>
                <Box sx={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', position: "relative", flexDirection: 'column', alignItems: 'center', marginLeft: 3 }}>
                    <Avatar src={state.peer.Image} sx={{ width: 90, height: 90 }} />
                    {state.peer.verified && <VerifiedIcon sx={{ position: "absolute", right: 0, bottom: 28, color: state.peer.CustomCard.badgeColor ? state.peer.CustomCard.badgeColor : "primary.main", zIndex: 1300 }} />}

                    <Typography variant='body1' sx={{ color: state.peer.CustomCard.textColor ? state.peer.CustomCard.textColor : 'white', fontWeight: 450, marginTop: 1 }}>{state.peer.Username}</Typography>

                  </Box>
                  <CardMedia>

                    <QrGenerator value={state.peer._id} peerId={state.id || ''} showSpotcardLogo={state.peer.CustomCard.showSpotcardLogoInQr} qrBackgroundColor={state.peer.CustomCard.qrBackgroundColor}
                    />
                  </CardMedia>
                </Box>
                <CardActions sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', marginTop: 2 }}>
                  {state.peer.CustomCard.logo ? (<img
                    src={state.peer.CustomCard.logo}
                    style={{ width: 20, height: 20, marginLeft: 15 }}
                    alt=''
                  />) :
                    <>
                      <Typography variant='body2' sx={{ color: '#373737', alignSelf: 'flex-start', fontSize: 13 }}>Design By</Typography>
                      <Link to='/' style={{ display: 'flex', alignSelf: 'flex-start', alignItems: 'center', marginLeft: -1, textDecoration: 'none', marginTop: 2 }}>
                        <img
                          src={logo}
                          style={{ width: 20, height: 20 }}
                          alt=''
                        />
                        <Typography sx={{
                          color: '#000',
                          fontSize: 13
                        }}>https://spotcard.co</Typography>
                      </Link>

                    </>
                  }

                </CardActions>
              </Card>
              <Typography variant='h6'
                sx={
                  {
                    display: "flex",
                    alignSelf: "flex-start"

                  }}>
                Bio</Typography>
              <Typography
                sx={{
                  display: "flex",
                  textAlign: "start",
                  width: 375,
                }}

                variant='body1'> {isExpanded ? state.peer.Bio : `${state.peer.Bio.slice(0, 100)}...`}</Typography>
              <Box onClick={toggleExpand} sx={{ display: "flex", justifyContent: "center", color: "primary.light" }} >
                <Show>
                  <Show.When isTrue={isExpanded}>
                    <ArrowDropUpOutlinedIcon sx={{ fontSize: 40, color: "#000" }} />
                  </Show.When>
                  <Show.Else>
                    <ArrowDropDownOutlinedIcon sx={{ fontSize: 40, color: "#000" }} />
                  </Show.Else>
                </Show>
                <Typography sx={{ display: "flex", alignItems: "center", fontSize: 12, fontStyle: "italic", }}>Show {isExpanded ? 'Less' : 'More'}</Typography>
              </Box>
              <Box>
                <Typography variant='h6' sx={{ display: 'flex', marginY: 2 }}>
                  Get Our App On
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                  <Link to={'https://play.google.com/store/apps/details?id=com.spotcard'} target='_blank'>
                    <img src='https://spotstay.co/assets/images/googleplay.png' alt='' style={{ width: 170, height: 45, borderRadius: 5 }} />
                  </Link>
                  <img src={AppStore} alt='' style={{ width: 170, height: 45, borderRadius: 7 }} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h5' sx={{ display: 'flex', alignSelf: 'flex-start' }}>Profiles</Typography>
              {filteredPlatforms.length === 0 ? (
                <Typography variant='body1' sx={{ marginTop: 2 }}>No profiles found</Typography>
              ) : (
                filteredPlatforms.map((profile, index) => (
                  <Box onClick={() => handleClickOpen(profile)} key={index} sx={{ display: 'flex', borderBottom: '1px solid silver', paddingY: 2, alignItems: 'center', }}>
                    <img src={profile.icon} style={{ width: 30, height: 30, marginRight: 15 }} alt='' />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: 'black' }}>
                      <Typography variant='body1'>{profile.name}</Typography>
                      <Typography variant='body2'>{profile.numberOfAccounts} Account{profile.numberOfAccounts > 1 ? 's' : ''}</Typography>
                    </Box>
                  </Box>
                ))
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                fullWidth
                PaperProps={{
                  sx: {
                    position: 'fixed',
                    bottom: 0,
                    m: 0,
                    width: '100%',
                    maxHeight: '120vh',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.01)',
                    borderRadius: '25px 25px 0 0'
                  },
                }}
                BackdropProps={{
                  sx: {
                    backgroundColor: 'rgba(0,0,0,0.3)',
                  },
                }}
                sx={{
                  zIndex: 1300,
                }}
              >
                <Toolbar>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {selectedProfile ? selectedProfile.name : ''}
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handleClose}>
                    Done
                  </Button>
                </Toolbar>

                {selectedProfile && selectedProfile.type === 'number' && (
                  <>
                    {selectedProfile.profiles.map((profile, index) => (
                      <List key={index}>

                        <ListItem >
                          <ListItemAvatar>
                            <Avatar src={selectedProfile.icon} alt={selectedProfile.name} />
                          </ListItemAvatar>
                          <ListItemText primary={profile.value} secondary={profile.notes}
                          />
                          {selectedProfile.buttons && selectedProfile.buttons.map((button) => renderButton(button, profile))}
                        </ListItem>
                        <Divider />

                      </List>
                    ))}

                  </>
                )}


                {selectedProfile && selectedProfile.type === 'phone' && (
                  <>
                    {selectedProfile.profiles.map((profile, index) => (
                      <List key={index}>

                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={selectedProfile.icon} alt={selectedProfile.name} />
                          </ListItemAvatar>
                          <ListItemText primary={profile.value} secondary={profile.notes}
                          />
                          {selectedProfile.buttons && selectedProfile.buttons.map((button) => renderButton(button, profile))}
                        </ListItem>
                        <Divider />

                      </List>
                    ))}

                  </>
                )}

                {selectedProfile && selectedProfile.type === 'link' && (
                  <>
                    {selectedProfile.profiles.map((profile, index) => (
                      <List key={index}>

                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={selectedProfile.icon} alt={selectedProfile.name} />
                          </ListItemAvatar>
                          <ListItemText primary={profile.value} secondary={profile.notes}
                          />
                          {selectedProfile.buttons && selectedProfile.buttons.map((button) => renderButton(button, profile))}
                        </ListItem>
                        <Divider />

                      </List>
                    ))}

                  </>
                )}


                {selectedProfile && selectedProfile.type === 'phoneNumber' && (
                  <>
                    {selectedProfile.profiles.map((profile, index) => (
                      <List key={index}>

                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={selectedProfile.icon} alt={selectedProfile.name} />
                          </ListItemAvatar>
                          <ListItemText primary={profile.value} secondary={profile.notes}
                          />
                          {selectedProfile.buttons && selectedProfile.buttons.map((button) => renderButton(button, profile))}
                        </ListItem>
                        <Divider />

                      </List>
                    ))}

                  </>
                )}

                {selectedProfile && selectedProfile.type === 'mail' && (
                  <>
                    {selectedProfile.profiles.map((profile, index) => (
                      <List key={index}>

                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={selectedProfile.icon} alt={selectedProfile.name} />
                          </ListItemAvatar>
                          <ListItemText primary={profile.value} secondary={profile.notes}
                          />
                          {selectedProfile.buttons && selectedProfile.buttons.map((button) => renderButton(button, profile))}
                        </ListItem>
                        <Divider />

                      </List>
                    ))}

                  </>
                )}

              </Dialog>
            </Box>
          </Box>
        </Box>
      </Box >

    </>
  );
}

export default GetPeer;
