import { Children } from 'react'

const Show: any = (props: any) => {
    let when: any = null
    let otherwise = null
    Children.forEach(props.children, children => {
        if (children.props.isTrue === undefined)
            otherwise = children
        else if (!when && children.props.isTrue === true)
            when = children
    })
    return when || otherwise
}

export default Show

Show.When = ({ isTrue, children }: any) => isTrue && children
Show.Else = ({ render, children }: any) => render || children