import React, { useState } from 'react'
import { Box, Button, CardHeader, Grid, Typography, Popover } from '@mui/material'
import { Card, CardContent } from '@mui/material';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleIcon from '@mui/icons-material/People';
import { Person } from '@mui/icons-material';


interface StateProps {
    selectedPopoverData: string
}

const Pricing = () => {
    const [state, setState] = useState<StateProps>({
        selectedPopoverData: ''
    })


    const cards = [
        { title: 'Personal Card', subTitle: 'Customize and brand your card', price: "$1", contentTitle: 'Includes:', content: ['Create unique digital business cards', 'Add your photo, contact details, logo and links', 'Unlimited card sharing', 'Keep track of everyone you meet'], backgroundColor: 'yellow' },
        { title: 'Business Card', subTitle: 'Scale across your team', price: "$10", contentTitle: "Everything in Personal, plus:", content: ['Choose from additional card designs and styles', 'Brand your QR code and link', 'Add badges,logos etc.'], backgroundColor: 'gold' },
        { title: 'Get Verified', subTitle: 'Get your Profile verified', price: "$100", contentTitle: 'Everything in Business and Personal, plus:', content: ['Get a verification tick added to your profile', 'Create powerfull email templates'], backgroundColor: 'orange' }
    ];


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, data: string) => {
        setAnchorEl(event.currentTarget);

        setState({
            ...state,
            selectedPopoverData: data
        })
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { selectedPopoverData } = state


    return (<section id="pricing">
        <Box sx={{ marginTop: 30 }}>
            <Box sx={{ marginBottom: 10 }}>
                <Typography variant='h4' sx={{marginBottom:2}} data-aos="fade-down">Pricing</Typography>
                <Typography variant='h5' sx={{ fontSize: { xs: 17, sm: 23,marginBottom:26 } }} data-aos="fade-down">Get 7 Days Free Trial for each plan</Typography>
                <Typography variant='h4' sx={{ fontSize: { xs: 19, sm: 28 } }} data-aos="fade-down">Ready to get started?</Typography>
                <Typography data-aos="fade-down" sx={{marginY:2}}>Sign up now and you'll be up and running on SpotCard in just minutes.</Typography>
                <Button data-aos="fade-down" variant="contained">Start Free Trial</Button>

            </Box>

            <Box sx={{ margin: { sm: 8, md: 10 } }}>
                <Typography data-aos="fade-up" variant='h4'>Plans</Typography>
                < Grid container spacing={{ sm: 1, md: 5 }} justifyContent="center" style={{ marginTop: '3rem' }}>

                    {cards.map((card, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} data-aos="fade-up">
                            <Card key={index} sx={{ marginX: 2, width: { sm: '80%', md: '100%' }, textAlign: 'start', marginBottom: 3 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', height: '27rem' }}>
                                    <CardHeader
                                        title={
                                            <div>
                                                <Typography data-aos="fade-down" variant='h6'
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontStyle: 'unset',
                                                        display: 'flex',
                                                        gap: 1,
                                                        alignItems: 'center',
                                                        color: 'primary.main'
                                                    }}
                                                >{card.title}
                                                    {card.title === 'Get Verified' && <VerifiedOutlinedIcon
                                                        sx={{ fontSize: 25, color: 'primary.main' }} />}</Typography>
                                                <Typography data-aos="fade-down" variant='body2' sx={{ fontStyle: 'italic', width: '100%' }}>{card.subTitle}</Typography>
                                                <Typography data-aos="fade-down" variant='h5' sx={{ fontWeight: 800, color: 'black' }}>{card.price}<em style={{ fontSize: 15 }}>/mo</em></Typography>
                                            </div>
                                        }
                                        action={

                                            card.title === 'Personal Card' ? <div style={{ display: 'flex' }}>
                                                <Person />
                                                <Typography data-aos="fade-down">1 User</Typography>
                                            </div> :
                                                <div>
                                                    <Button variant='contained' style={{ display: 'flex', gap: 2 }} onClick={(event) => handleClick(event, card.title)}>
                                                        <PeopleIcon data-aos="fade-down" />
                                                        <Typography data-aos="fade-down"> 1-50 users</Typography>
                                                    </Button>
                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        {selectedPopoverData === 'Business Card' && (
                                                            <div>
                                                                <Button style={{ display: 'flex', gap: 2 }}>
                                                                    <PeopleIcon data-aos="fade-down" />
                                                                    <Typography data-aos="fade-down"> 51-100 users</Typography>
                                                                    <Typography variant='body1' data-aos="fade-down" sx={{color:'orange',marginLeft:1,fontWeight:'bold'}}>$15</Typography>
                                                                </Button>
                                                                <Button style={{ display: 'flex', gap: 2 }} >
                                                                    <PeopleIcon data-aos="fade-down" />
                                                                    <Typography data-aos="fade-down"> 101-200 users</Typography>
                                                                    <Typography variant='body1' data-aos="fade-down" sx={{color:'orange',marginLeft:1,fontWeight:'bold'}}>$25</Typography>
                                                                </Button>
                                                                <Button style={{ display: 'flex', gap: 2 }} >
                                                                    <PeopleIcon data-aos="fade-down" />
                                                                    <Typography data-aos="fade-down"> 200+ users</Typography>
                                                                    <Typography variant='body1' data-aos="fade-down" sx={{color:'orange',marginLeft:1,fontWeight:'bold'}}>$50</Typography>
                                                                </Button>
                                                            </div>

                                                        )}
                                                        {selectedPopoverData === 'Get Verified' && (
                                                            <Typography sx={{ p: 2 }}> Coming Soon.</Typography>
                                                        )}
                                                    </Popover>

                                                </div>
                                        }

                                        sx={{ textAlign: 'start', backgroundColor: card.backgroundColor }} />

                                    <CardContent>
                                        <Typography sx={{ fontWeight: 500, marginBottom: 2 }}>{card.contentTitle}</Typography>
                                        {card.content.map((content, contentIndex) => {
                                            return (
                                                <Box key={contentIndex} data-aos="fade-up">

                                                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'grey', marginBottom: 1 }}>
                                                        <CheckCircleOutlineIcon sx={{ color: 'primary.main' }} />
                                                        {content}
                                                    </Typography>
                                                </Box>

                                            );
                                        })}
                                    </CardContent>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                                        <Button variant='contained' data-aos="fade-up" sx={{ display: 'flex', alignItems: 'center' }}>Click for more info</Button>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    ))}

                </Grid>

            </Box>
        </Box >
    </section>
    )
}

export default Pricing;