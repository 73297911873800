const isProduction = true
export const env = isProduction ? 'production' : 'development',
    terms_url = 'hhttps://spotcard.co/policies',
    url = isProduction ? 'https://spotcard.co' : 'http://192.168.100.13:3002',
    urlwebSocket = isProduction ? 'wss://spotcard.co' : 'ws://192.168.100.13:3002',
    appName = 'SpotCard',

    public_encryption_key = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxq1+Aup5ioRnI1TrDF4F
    Bu7Sr3CyQu+qOYW9elaUCwVRydMwZXh8g8edoPEE/Y0fdYgTKwJ2qWBQ5AlwLMuN
    BGwvsJeaWu9Y1OUarj30H70K1Jili0Vcwz/H4AwPupqMcBZIzzwOETaChlomipLu
    xhnZqdzGcFVnA6V24P51WAst8qGxXQQccJNCI4uNCecfAzDmh8TO1qCpktfSX229
    Z5yGsFQyBRFhSLxiICHlCUIYi4X4Sq9RRFQNIVWYi3dBNjzOBgk+DkZeZ6dU3Lyc
    1xPBmCe0oz07edezOq1dZGQYIrN4DPza8G3UvKlGlOKixpQVayfsrU9B5FpGoVWr
    0TSUyrz51OlU7mfQGSuNgHtAwD23NUQ+EjBSoeOHhZszLtqaTVSinoLj6xI4zGzY
    dyhX5hMS5mIW7Ac+V5pU3SIUN/T5wWuPGNTVUvlMTf6DIWV8XrJTlahVTe7mR0cE
    CAkBZuAjr+EDGb/zQNYNJ+8YwfoFoSL0GnTYGseyLUmOyktBpUwizLe1ofjQgDc5
    nhonLPpo+JULC+pBjZCVw2wnV7JFd1E6TrcAFdiSJ3lfbpKqKARG8V8zCNHyVdrr
    VFzbAgYxE1n5sMSvXB6jambYVAaNR2GyLYcdVb2ELY7hXlpMp/M0MrabTqBXRp3/
    kepecHASyOr0sAIs7QKdX4ECAwEAAQ==
    -----END PUBLIC KEY-----`

