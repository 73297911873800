import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import card from "../Assets/images/card.jpeg";

const Home = () => {
  return (
    <section id="home">
      <Paper elevation={12} data-aos="fade-down" sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        backgroundColor: '#8d2fd6',
        marginTop: { xs: 17, sm: 20, md: 23 },
        marginBottom: 10,
        marginLeft: { xs: 1, sm: 4, md: 9, lg: 13 },
        marginRight: { xs: 1, sm: 4, md: 9, lg: 13 },
        paddingLeft: { xs: 5, sm: 7, md: 10 },
        paddingRight: { xs: 5, sm: 7, md: 10 },
        paddingBottom: 7,
        paddingTop: 6,
        borderTopLeftRadius: { xs: 60, sm: 90, lg: 130 },
        borderBottomRightRadius: { xs: 60, sm: 90, lg: 130 },
        gap: { xs: 3, md: 5, lg: 10 },
        alignItems: 'flex-start'
      }}>

        <Box sx={{ width: { xs: '100%', lg: '50%' }, textAlign: { xs: 'center', md: 'start' }, marginBottom: { xs: 4, md: 0 } }}>
          <Typography data-aos="fade-down" variant='h2' sx={{
            color: '#C0C0C0',
            fontWeight: 600,
            marginBottom: { xs: 3, md: 5 },
            fontSize: { xs: '1.7rem', sm: '2.1rem', md: '2.rem' },
          }}>No 1 Professional Digital Business Card Platform</Typography>
          <Typography variant='h4' data-aos="fade-up"
            sx={{
              color: '#C0C0C0',
              marginBottom: 3,
              fontSize: { xs: 17,sm: 19, md: 22 } 
            }}>Show customers you’re a pro with a customized card that matches your Business Trademark.</Typography>
          <Typography data-aos="fade-up" sx={{ color: '#C0C0C0', marginBottom: 3, fontSize: { xs: '0.85rem', sm: '1rem' } }}>For as low as <Typography variant="caption" gutterBottom>$1</Typography></Typography>
        </Box>

        {/* Right side content */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', md: 'flex-start' },

          }}>
          <Typography data-aos="fade-down" sx={{
            fontSize: { xs: '1.4rem', sm: '2rem', md: '2.2rem' },
            marginBottom: 3,
            color: 'silver'
          }}>Custom Card</Typography>
          <img src={card} alt='' data-aos="fade-up" style={{ height: 'auto', width: '100%', maxWidth: 500, objectFit: 'contain', borderRadius: 25 }} />
        </Box>
      </Paper>
    </section>
  );
};

export default Home;
